module.exports = {
    navbar: {
        home: "导航首页",
        about: "关于我们",
        download: "应用下载",
        lang: "简体中文"
    },
    feature: {
        title: "优点和特色",
        security: "安全",
        security_intro: "采用先进的加密技术保障信息安全",
        cloud: "云端",
        cloud_intro: "支持多端登录，消息自动同步",
        moments: "朋友圈",
        moments_intro: "分享和发布动态",
        freedom: "自由",
        freedom_intro: "对消息和文件大小没有限制",
        fast: "快速",
        fast_intro: "为用户提供超快的连接和使用体验",
        wallet: "钱包",
        wallet_intro: "支持多币种管理、支付及红包",
        robot: "机器人",
        robot_intro: "通过API自由创建机器人",
        development: "开发",
        development_intro: "多元、自由的平台",
        fun: "趣味",
        fun_intro: "发现感兴趣的东西、遇到有趣的人",
        video_call: "视频通话",
        video_call_intro: "免费体验高质量视频通话",
        nearby: "附近的人",
        nearby_intro: "基于地理位置发现好友",
        mini_program: "小程序",
        mini_program_intro: "无需下载安装、即可体验"
    },
    footer: {
        subscribe_hint: "请输入您的邮箱进行订阅",
        subscribe_btn_text: "邮箱订阅",
        official_nav: "官网导航",
        home: "首页",
        about: "关于我们",
        download: "应用下载",
        download_nav: "应用下载"
    },
    about: {
        title: "关于我们",
        slogan: "一个更合适的IM软件",
        introduction1: "Adove是一款注重速度和安全性的即时通讯应用。它超快，简单，安全且免费。您可以发送无限量的消息，照片，视频和任何类型的文件（.doc，.zip，.pdf等）。Adove群组可添加群成员高达1,000,000名，您可以创建频道向无限数量的订阅者广播消息。我们开发Adove，以便在没有通常的警告的情况下快速安全地发送消息。",
        introduction2: "Adove适合所有想要快速，安全可靠的消息传递的人。人人都可使用Adove。对于商业用户和小型团队，Adove提供大型群组，桌面应用和强大的文件共享选项; Adove群组可添加群成员高达1,00,000名，我们支持回复，提及和标签，以帮助维持秩序并保持大型社区的有效沟通。",
        introduction3: "如果您对图片更感兴趣，Adove提供了gif动画搜索，这是一个最先进的照片编辑器和一个开放的贴纸平台（在这里或这里找到一些很酷的贴纸）。更重要的是，您无需担心设备上的磁盘空间。Adove拥有云服务和缓存管理选项，它几乎不会占用您的手机空间。",
        introduction4: "Adove可用于智能手机，平板电脑和PC，并且Adove支持ios（6及更高版本），Android（4及更高版本）。您还可以使用Windows或OSX桌面应用程序进行安装。随时随地，您可以在大多数设备上使用您自己的手机号码登录并使用Adove。",
        introduction5: "在这个数据泛滥的时代，Adove非常关注用户的隐私。在这个问题上，我们一致认为最重要的是： 1.保护用户的聊天信息，防止第三方（如官员，雇主等）窥探 2.保护用户的个人资料，禁止第三方（如营销人员，广告商等）获取该信息。我们知道，每个用户都极其重视隐私问题，这也与每个用户的生活密切相关，所以保护用户的隐私，是Adove的首要任务。我们会努力做，并且会做得更好",
        download: "下载",
        application: "应用软件",
        download_btn_text: "立即下载"
    },
    download: {
        title: "下载",
        intro: "支持平台 IOS, Android, windows and Google paly",
        btn_text: "立即下载"
    }
}