import { createApp } from 'vue'
import { createStore } from 'vuex'
import VueCookies from 'vue-cookies'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router/index'

import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'


const store = createStore({
    state: {
        currentTabBar: sessionStorage.getItem('CURRENT_TAB_BAR') ?? ''
    },
    mutations: {
        setTabBar(state, tabName) {
            state.currentTabBar = tabName
            sessionStorage.setItem('CURRENT_TAB_BAR', tabName)
        }
    },
    actions: {
        setTabBar(context, tabName) {
            context.commit('setTabBar', tabName)
        }
    }
})

const defaultLanguage = 'zh';

function GetLanguageCookieValue() {
    let _language = defaultLanguage;
    let _cookie = VueCookies.get('language');
    if (_cookie == undefined || _cookie == '' || _cookie == null) {
        _language = defaultLanguage;
    } else {
        _language = _cookie;
    }
    return _language;
}

const messages = {
    en: require('./lang/en'),
    zh: require('./lang/zh')
}

const i18n = createI18n({
    legacy: false,
    locale: GetLanguageCookieValue(),
    fallbackLocale: defaultLanguage,
    messages
})

createApp(App).use(router).use(store).use(i18n).mount('#app')
