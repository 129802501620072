module.exports = {
    navbar: {
        home: "Home",
        about: "About",
        download: "Download",
        lang: "English"
    },
    feature: {
        title: "Advantages and Features",
        security: "Security",
        security_intro: "Adopt advanced encryption technology to ensure information security",
        cloud: "Cloud",
        cloud_intro: "Supports multi-port login and automatic message synchronization",
        moments: "Moments",
        moments_intro: "Share and publish updates",
        freedom: "Freedom",
        freedom_intro: "There are no limits on message and file sizes",
        fast: "Fast",
        fast_intro: "To provide users with super fast connection and use experience",
        wallet: "Wallet",
        wallet_intro: "Support multi-currency management, payment and red envelopes",
        robot: "Robot",
        robot_intro: "Free creation of robots through API",
        development: "Development",
        development_intro: "Pluralistic and free platform",
        fun: "Fun",
        fun_intro: "Discover interesting things and meet interesting people",
        video_call: "Video Call",
        video_call_intro: "Experience high quality video calling for free",
        nearby: "Nearby",
        nearby_intro: "Discover friends based on location",
        mini_program: "Mini Program",
        mini_program_intro: "Experience without download and installation"
    },
    footer: {
        subscribe_hint: "Please enter your email address",
        subscribe_btn_text: "Subscribe",
        official_nav: "Navigation",
        home: "Home",
        about: "About Us",
        download: "Download",
        download_nav: "Download"
    },
    about: {
        title: "About Us",
        slogan: "A more suitable IM software",
        introduction1: "Adove is a messaging app that focuses on speed and security. It's super fast, easy, safe and free. You can send unlimited messages, photos, videos and any type of file (.doc,.zip,.pdf, etc.). Adove groups can add up to 1,000,000 group members, and you can create channels to broadcast messages to an unlimited number of subscribers. We developed Adove to send messages quickly and securely without the usual warnings.",
        introduction2: "Adove is for anyone who wants fast, secure and reliable messaging. Adove is available to everyone. For business users and small teams, Adove offers large groups, desktop applications and powerful file sharing options; Adove groups can add up to 1,000,000 group members, and we support replies, mentions, and tags to help maintain order and keep large communities communicating effectively.",
        introduction3: "If you're more interested in pictures, Adove offers animated GIF Search, a state of the art photo editor and an open sticker platform (find some cool stickers here or here). More importantly, you don't have to worry about disk space on the device. Adove has cloud services and cache management options that take up almost no space on your phone.",
        introduction4: "Adove is available for smartphones, tablets and PCS, and is supported for ios (version 6 and later), Android (version 4 and later). You can also install using Windows or OSX desktop applications. Anytime, anywhere, you can log in and use Adove with your own mobile number on most devices.",
        introduction5: "In this era of data overload, Adove is very concerned about users' privacy. On this issue, we agree that the most important things are: 1. Protect users' chat information and prevent third parties (such as officials, employers, etc.) from snooping 2. Protect users' personal data and prohibit third parties (e.g., marketers, advertisers, etc.) from accessing this information. We know that every user takes privacy extremely seriously, which is closely related to every user's life, so protecting users' privacy is a top priority of Adove. We will work hard and we will do better",
        download:"Download",
        application:"Application",
        download_btn_text: "Download"
    },
    download:{
        title:"Download",
        intro:"Support IOS, Android, windows and Google paly",
        btn_text: "Download"
    }
}