import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        name: 'home',
        path: '/',
        component: () => import('../pages/Home.vue')
    },
    {
        name: 'about',
        path: '/about',
        component: () => import('../pages/About.vue')
    },
    {
        name: 'download',
        path: '/download',
        component: () => import('../pages/Download.vue')
    },
    {
        name: 'privacy',
        path: '/privacy',
        component: () => import('../pages/Privacy.vue')
    }
]


const router = createRouter({
    history: createWebHistory('/'),
    routes
})


router.beforeEach((to, from, next) => {
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
    next()
})

export default router
